import MainHeading from "../../components/heading/MainHeading";
import linkedin from '../../images/linkedin.svg';
import MainButton from "../../components/Button/MainButton";
const Contact = () => {
    return (
        <section id="contact">
            <div className="container mx-auto">
                <div className="contact-wrapper text-center">
                    <MainHeading
                        alignCenter={true}
                        title="Contact"/>
                    <div className="contact-description">
                        <p>Let's build awesome things together! You can reach out to me via email at
                            <a href="mailto:trifugabrielaemilia30@yahoo.com" target="_blank"  rel="noreferrer"
                               title="trifugabrielaemilia30@yahoo.com"> trifugabrielaemilia30@yahoo.com</a> or connect
                            with me through social media using the <a className="social-icon" href="https://www.linkedin.com/in/gabriela-emilia-trifu/" target="_blank"  rel="noreferrer"><img  src={linkedin} alt="linkedin"/></a></p>
                    </div>
                    <MainButton
                       link="resume.pdf"
                      text="Resume"
                    />
                </div>
            </div>
        </section>
    )
}

export default Contact