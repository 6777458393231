import ProjectItem from "../../components/includes/ProjectItem";
import {useEffect, useState} from "react";
import MainHeading from "../../components/heading/MainHeading";

const Projects = () => {
    const [projects, setProjects] = useState([])
    const apiUrl = process.env.REACT_APP_API_URL;
    useEffect(() => {
        const fetchProjects = async () => {
            try {
                const res = await fetch(`${apiUrl}/projects`)
                const data = await res.json()
                setProjects(data);
            } catch (error) {
                console.log(error)
            }
        }
        fetchProjects()
    },  [apiUrl]);

    return (
        <section id="projects">
            <div className="container mx-auto">
                <div className="projects-wrapper">
                    <MainHeading
                        alignCenter={false}
                        title="Projects"/>
                    <div className="projects-list">
                        {projects.map((project) => (
                            <ProjectItem
                                key={project.id}
                                project={project}
                            />
                        ))}
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Projects