const Project = ({project}) => {
    return (
        <div className="project-item">
            <div className="project-content p-6 flex flex-col-reverse lg:flex-row  w-100">
                <div className="project-content__info w-full lg:w-1/2">
                    <div className="project-heading">
                        <h2 className="project-title">
                            {project.title}
                        </h2>
                    </div>
                    <ul className="flex ul-tech  gap-1 flex-wrap md:text-lg  mt-2">
                        {project.skills.map((skill, index) => (
                            <li key={index}>{skill.name}</li>
                        ))}
                    </ul>
                    <div className="project-description my-4">
                        <p>
                            {project.description}
                        </p>
                    </div>
                    <div className="project-button">
                        <a className="project-btn px-2 py-2"
                           href={project.link}
                           target="_blank"
                        >
                            Demo
                        </a>
                        <a className="project-btn px-2 py-2"
                           href={project.link2}
                           target="_blank"
                        >
                            Code
                        </a>
                    </div>
                </div>
                <div className="project-image w-full lg:w-1/2">
                    <img alt="Jobs Platform for ICT professionals cover"
                         className="project_img"
                         src={require(`../../images/${project.image}`)}
                    />
                </div>
            </div>
        </div>
    )
}
export default Project