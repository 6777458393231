const Skill = ({skill}) =>{
    return (
        <div className="about-stack__list flex items-center">
            <img className="w-full block me-2 lg:me-4"
                 src={skill.image}
                 alt={skill.name}/>
            <p>{skill.name}</p>
        </div>
    )
}
export default Skill