import {useEffect, useState} from "react";
import SkillItem from "../../components/includes/SkillItem";

const AboutUs = () => {
    const [skills, setSkills] = useState([]);

    const apiUrl = process.env.REACT_APP_API_URL;

    useEffect(() => {
        const fetchSkills = async () => {
            try {
                const res = await fetch(`${apiUrl}/skills`);
                const data = await res.json();
                setSkills(data);
            } catch (error){
                console.log(error)
            }
        }
        fetchSkills()
    }, [apiUrl]);

    return (
        <section id="about-us">
            <div className="container mx-auto">
                <div className="about-us__wrapper text-center">
                    <div className="about-us__text">
                        <h2>Hi! I am <span>Gabriela</span>!</h2>
                        <p>I'm a <span>frontend developer</span> ready to bring your <span>web projects</span> to life.
                            Let's dive in and <span>get started!</span></p>
                    </div>
                    <div className="about-us__stack">
                        <h2>MY SKILLS</h2>
                        <div className="about-stack__lists flex flex-wrap  gap-x-2 sm:gap-x-5 justify-center">
                            {skills.map((skill) => (
                                <SkillItem
                                key={skill.id}
                                skill={skill}
                                />
                            ))}
                        </div>
                    </div>
                </div>
            </div>
        </section>
    )
}
export default AboutUs