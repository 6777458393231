import './Footer.scss'
const Footer = () => {
    return (
        <footer id="footer">
            <div className="container mx-auto">
                <div className="footer-wrapper flex flex-col lg:flex-row flex-wrap lg:justify-between justify-center items-center">
                    <div className="footer-wrapper__copyright">
                        <p>© 2024. Made with 💜 by gabri.</p>
                    </div>
                    <div className="footer-wrapper__">
                        <p>Thank you for being here.</p>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer