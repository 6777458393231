import './Header.scss'
import logo from '../../images/logo-test1.png';
import resume from '../../images/resume.pdf'
import { BrowserRouter as Router, Link } from 'react-router-dom';
import {useState} from "react";

const Header = () => {
    const [isActive, setIsActive] = useState(false);

    const handleClick = () => {
        setIsActive(!isActive);
    };

    return (
        <header id="header" className="sticky top-0 z-50  shadow-md">
            <div className="container mx-auto">
                <div className="header-wrapper flex justify-between items-center">
                    <a href="/" className="header-wrapper__logo">
                        <img className="w-full block" src={logo} alt="logo"/>
                    </a>
                    <div className={`${isActive ? 'active' : ''} header-wrapper__links`}>
                        <ul className="flex flex-col lg:flex-row">
                            <Router>
                                <li className="mx-4"><Link to="#about">About</Link></li>
                                <li className="mx-4"><Link to="#projects">Projects</Link></li>
                                <li className="mx-4"><Link to="#contact">Contact</Link></li>
                                <li className="mx-4"><Link target="_blank" to={resume}>Resume </Link></li>
                            </Router>
                        </ul>
                    </div>
                    <div id="menu-bar" className={`${isActive ? 'active' : ''} menu flex flex-col lg:hidden`} onClick={handleClick}>
                        <div id="bar1" className="bar"></div>
                        <div id="bar2" className="bar"></div>
                        <div id="bar3" className="bar"></div>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default Header