import './Homepage.scss'
import AboutUs from "./homepage/AboutUs";
import Projects from "./homepage/Projects";
import Contact from "./homepage/Contact";

const Homepage = () => {
    return (
        <div id="homepage">
            <AboutUs/>
            <Projects/>
            <Contact/>
        </div>
    )
}
export default Homepage